import { uniqWith } from 'lodash-es'
import type { TipItem, TrieNode } from './type'

export function createTireTip(tipMap: Record<string, TipItem[]> = {}) {
  const root: TrieNode = { char: '*' }
  for (const key in tipMap) {
    let parent = root
    for (let i = 0; i < key.length; i++) {
      const isLast = i === key.length - 1
      const char = key[i]
      if (!parent.children) parent.children = []
      const node = parent.children.find(n => n.char === char)

      if (node) {
        parent = node
      } else {
        const newNode = { char }
        parent.children = [...(parent.children || []), newNode]
        parent = newNode
      }

      if (isLast) {
        parent.end = true
        parent.tips = tipMap[key]
      }
    }
  }

  return root
}

export function getTireTips(tipNode?: TrieNode | null) {
  if (!tipNode) return []
  let newTips: TipItem[] = []
  loopTire(tipNode, each => {
    newTips.push(...(each.tips || []))
  })
  newTips = uniqWith(
    newTips,
    (a, b) => a.value === b.value && a.color === b.color,
  )
  return newTips
}

export function loopTire(
  node: TrieNode | undefined,
  call: (node: TrieNode) => void,
) {
  if (!node) return
  call(node)

  if (!node.children) return

  for (const each of node.children) {
    loopTire(each, call)
  }
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function colorSerializer(color: string) {
  const colorSrt = color.replace(/[.#*+?^${}()|[\]\\]/g, '')
  return `text-editor-highlight-color-${colorSrt}`
}
