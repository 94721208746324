import type { MenuProps } from 'antd'
import { range } from 'lodash-es'
import {
  CustomLoopType,
  ExecuteType,
  LoopType,
  TaskStatusType,
  TaskType,
} from '@bty/global-types/task'

export const TaskOption = [
  {
    value: TaskType.PLUGIN,
    label: '插件',
  },
  {
    value: TaskType.FLOW,
    label: '工作流',
  },
  {
    value: TaskType.AGENT,
    label: '指令',
  },
]

export const ExecuteOption = [
  { value: ExecuteType.IMMEDIATELY, label: '立即执行' },
  { value: ExecuteType.SCHEDULE, label: '定时执行' },
]

export const LoopOption = [
  {
    value: LoopType.NONE,
    label: '不重复',
  },
  {
    value: LoopType.DAILY,
    label: '每天',
  },
  {
    value: LoopType.WEEKLY,
    label: '每周',
  },
  {
    value: LoopType.MONTHLY,
    label: '每月',
  },
  {
    value: LoopType.WORKDAY,
    label: '每个工作日',
  },
  {
    value: LoopType.CUSTOM,
    label: '自定义重复',
  },
]

export const CustomLoopOption = [
  {
    value: CustomLoopType.MINUTE,
    label: '按分钟',
  },
  {
    value: CustomLoopType.HOUR,
    label: '按小时',
  },
  {
    value: CustomLoopType.WEEK,
    label: '按周',
  },
]

export const MinuteOption = range(1, 60).map(each => ({
  value: `${each}`,
  label: `每 ${each} 分钟`,
}))

export const HourOption = range(1, 24).map(each => ({
  value: `${each}`,
  label: `每 ${each} 小时`,
}))

export const WeekMap: Record<number, string> = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '日',
}

export const PresentPanelMenu: MenuProps['items'] = [
  {
    key: 'detail',
    label: (
      <div className='px-[6px] h-[30px] w-[60px] flex items-center'>查看</div>
    ),
  },
]

export const PanelMenu: MenuProps['items'] = [
  {
    key: 'edit',
    label: (
      <div className='px-[6px] h-[30px] w-[60px] flex items-center'>编辑</div>
    ),
  },
  {
    key: 'remove',
    label: (
      <div className='px-[6px] h-[30px] w-[60px] flex items-center text-[#FF5219]'>
        删除
      </div>
    ),
  },
]

export const DEFAULT_TASK_CONFIG = {
  task_start_time: null,
  task_type: LoopType.NONE,
  custom_task_type: CustomLoopType.MINUTE,
  custom_task_config: '',
}

export const TaskStatusMap = {
  [TaskStatusType.SUCCEEDED]: {
    text: '执行成功',
  },
  [TaskStatusType.FAILED]: {
    text: '执行失败',
  },
  [TaskStatusType.RUNNING]: {
    text: '执行中',
  },
  [TaskStatusType.PENDING]: {
    text: '等待执行',
  },
}

export { TaskType, ExecuteType, LoopType, CustomLoopType }
