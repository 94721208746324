import type { SelectProps } from 'antd'
import { Select as AntdSelect } from 'antd'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import type { BaseOptionType, DefaultOptionType } from 'antd/es/select'
import { THEME } from '@bty/constant'
import { Assets } from '../base'
import { Size, TextSize } from '../const'

export const StyledSelect = styled(AntdSelect)`
  && {
    &.ant-select-single {
      height: ${Size.middle};
    }

    &.ant-select-multiple {
      min-height: ${Size.middle};
    }
    .ant-select-item {
      margin-bottom: 4px;
    }
    .ant-select-selector {
      background-color: ${rgba(THEME.colors.bg_3, 0.06)};
      border-color: transparent;
      box-shadow: none;
      height: ${Size.middle};

      .ant-select-selection-placeholder {
        line-height: ${Size.middle};
      }
    }

    .ant-select-selection-item {
      line-height: ${Size.middle};
    }

    &.ant-select-disabled,
    &.ant-select-disabled.ant-select-multiple {
      .ant-select-selector {
        background-color: ${rgba(THEME.colors.bg_3, 0.06)};
      }
    }

    &.ant-select-multiple {
      &.ant-select-sm {
        .ant-select-selector {
          padding: 4px;
        }
      }

      .ant-select-selection-item {
        color: ${THEME.colors.font};
        border-radius: 4px;
        background: #fff;
        align-items: center;
        margin: 0 4px 0 0;
        padding: 0 8px;
      }
    }

    &.ant-select-sm {
      &.ant-select-single {
        font-size: ${TextSize.small};
        height: ${Size.small};
      }
      .ant-select-selector {
        font-size: ${TextSize.small};
        height: ${Size.small};
        padding: 4px 12px;

        .ant-select-selection-placeholder {
          line-height: 23px;
        }
        .ant-select-selection-item {
          font-size: ${TextSize.small};
          height: 23px;
        }
      }
    }

    &.ant-select-lg {
      .ant-select-selector {
        font-size: ${TextSize.middle};
        height: ${Size.large};
        border-radius: 8px;
        line-height: ${Size.large};

        .ant-select-selection-item {
          font-size: ${TextSize.middle};
          height: 37px;
        }

        .ant-select-selection-placeholder {
          line-height: ${Size.large};
        }
      }
    }
  }
` as unknown as typeof AntdSelect

export function Select<T, V extends DefaultOptionType | BaseOptionType>(
  props: SelectProps<T, V>,
) {
  return (
    <StyledSelect
      suffixIcon={
        <Assets path='arrow.svg' className='w-[12px] text-font_1 mr-[2px]' />
      }
      {...props}
      value={props.value === '' ? undefined : props.value}
      popupClassName={`${props.popupClassName ?? ''} keyu-dropdown-menu-${
        props.size
      }`}
    />
  )
}

Select.Option = AntdSelect.Option
Select.OptGroup = AntdSelect.OptGroup
