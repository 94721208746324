export const FILE_MIME_TYPE_MAP = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  pdf: 'application/pdf',
  reference: 'application/marking-content+txt',
  webpage: 'application/webpage-content+json',
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  html: 'text/html',
  mp3: 'audio/mp3',
  mpeg: 'audio/mpeg',
  wav: 'audio/wav',
  aac: 'audio/aac',
  ogg: 'audio/ogg',
  webm: 'audio/webm',
  m4a: 'audio/m4a',
  'x-m4a': 'audio/x-m4a',
  wma: 'audio/wma',
  'x-ms-wma': 'audio/x-ms-wma',
  amr: 'audio/amr',
  flac: 'audio/flac',

  mp4: 'video/mp4',
  avi: 'video/avi',
  'x-avi': 'video/x-msvideo',
  mov: 'video/quicktime',
  flv: 'video/x-flv',
  wmv: 'video/x-ms-wmv',
  'v-webm': 'video/webm',
  'v-ogg': 'video/ogg',
  'x-msvideo': 'video/x-msvideo',
  'x-mpeg': 'video/mpeg',
  'x-matroska': 'video/x-matroska',
  'x-ms-asf': 'video/x-ms-asf',

  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  csv: 'text/csv',
} as const

export interface FileType {
  name: string
  type?: string
  size?: number
  id?: string
  upload_file_id?: string
  url?: string
}

export type FileMimeType =
  | (typeof FILE_MIME_TYPE_MAP)[keyof typeof FILE_MIME_TYPE_MAP]
  | `text/${string}`
  | 'init'
  | 'image'
  | 'audio'
  | 'video'
  | 'application/marking-content+txt'
  | 'application/webpage-content+json'
