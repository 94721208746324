import { useBoolean, useSize } from 'ahooks'
import { Popover, Form, message } from 'antd'
import { memo, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Button, TextArea } from '@bty/components'
import { useChatAPI } from '../../hooks/useChatAPI'
import { Icon } from '../base/Icon'

interface DislikePopoverProps {
  trigger: React.ReactNode
  record_id: string
  feedback_id?: string
  refreshChatHistory: () => void
}

// const { TextArea } = Input

const StyledTag = styled.span<{ selected?: boolean }>`
  padding: 9px 12px;
  color: rgba(23, 23, 29, 0.8);
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid rgba(225, 225, 229, 0.8);
  font-weight: 500;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(98, 105, 153, 0.08),
        rgba(98, 105, 153, 0.08)
      ),
      #ffffff;
  }
  ${({ selected }) =>
    selected &&
    `
    color: #7b61ff;
    border: 1px solid #7b61ff;
    background: linear-gradient(
        0deg,
        rgba(123, 97, 255, 0.08),
        rgba(123, 97, 255, 0.08)
      ),
      #ffffff;
  `}
`

const tagList = [
  '回答完全不对',
  '回答有遗漏',
  '回答范围过大',
  '未准确理解问题',
  'agent缺少技能',
]

const MOBILE_MODE_WIDTH = 768
const MOBILE_MODAL_WIDTH = 300
const PC_MODAL_WIDTH = 504

export function InternalDislikePopover(props: DislikePopoverProps) {
  const { trigger, record_id, refreshChatHistory, feedback_id } = props

  const ref = useRef<HTMLDivElement>(null)
  const [open, { setTrue: setOpen, setFalse: setClose }] = useBoolean(false)

  const [form] = Form.useForm()
  const { chatAPI } = useChatAPI()

  const [selectedTag, setSelectedTag] = useState<string | null>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const selectTag = (tag: string) => {
    setSelectedTag(prev => (prev === tag ? null : tag))
    const textArea = textAreaRef.current
    if (textArea) {
      const newValue = tag === selectedTag ? '' : `${tag}。`
      form.setFieldsValue({ reason: newValue })

      // 设置光标位置到内容末尾
      setTimeout(() => {
        textArea.focus()
      }, 0)
    }
  }

  const resetState = () => {
    setSelectedTag(null)
    form.resetFields()
  }

  const handleClose = () => {
    setClose()
    resetState()
  }

  const onSubmit = () => {
    form.validateFields().then(() => {
      const id = record_id.split('_')[0]
      chatAPI.Feedback.doRecordFeedbackDislike({
        record_id: id,
        user_opinion: form.getFieldValue('reason'),
      }).then(() => {
        refreshChatHistory?.()
        handleClose()
      })
    })
  }

  const handleOpen = () => {
    if (feedback_id) {
      message.warning('意见已反馈，不可重复提交')
      return
    }
    setOpen()
  }

  const size = useSize(document.body)

  const width = useMemo(() => {
    return (size?.width || 0) < MOBILE_MODE_WIDTH
      ? MOBILE_MODAL_WIDTH
      : PC_MODAL_WIDTH
  }, [size])

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    if (selectedTag && !value.includes(selectedTag)) {
      setSelectedTag(null)
    }
  }

  return (
    <Popover
      arrow={false}
      placement='topLeft'
      open={open}
      title={false}
      zIndex={990}
      overlayInnerStyle={{
        padding: 0,
        width,
      }}
      content={
        <div className='relative flex flex-col min-h-[280px] p-[24px]'>
          <div
            className='absolute top-[24px] right-[24px] flex items-center justify-center w-[20px] h-[20px] rounded-[20px] cursor-pointer hover:bg-[#626999] hover:bg-opacity-[0.12]'
            onClick={handleClose}
          >
            <Icon path='close.svg' className='w-[16px] text-font_1' />
          </div>
          <div className='mb-[24px] text-[18px] font-[500] text-[#17171d]'>
            意见反馈
          </div>
          <div className='flex flex-wrap gap-[8px] mb-[16px]'>
            {tagList.map(tag => (
              <StyledTag
                key={tag}
                selected={selectedTag === tag}
                onClick={() => selectTag(tag)}
              >
                {tag}
              </StyledTag>
            ))}
          </div>
          <Form form={form}>
            <Form.Item
              name='reason'
              rules={[{ required: true, message: '请输入您的反馈意见内容' }]}
            >
              <TextArea
                ref={textAreaRef}
                placeholder='请输入您的反馈意见内容'
                style={{ minHeight: '80px' }}
                onChange={handleTextAreaChange}
              />
            </Form.Item>
          </Form>
          <div className='flex justify-end gap-[16px]'>
            <Button type='primary' onClick={onSubmit}>
              提交
            </Button>
          </div>
        </div>
      }
    >
      <div onClick={handleOpen} ref={ref}>
        {trigger}
      </div>
    </Popover>
  )
}

export const DislikePopover = memo(InternalDislikePopover)
