import { useDebounceFn, useDeepCompareEffect, useMemoizedFn } from 'ahooks'
import type { DetailedHTMLProps, HTMLAttributes, RefObject } from 'react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { moreRef } from '../../../../utils/react'

type DivAttr = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'onChange'
>

interface ContentEditableProps extends DivAttr {
  divRef: RefObject<HTMLDivElement>
  editable: boolean
  value: string
  onChange: (value: string) => void
}

function getRange(node: Node) {
  if (!node) return null
  const selection = getSelection()
  const range = selection?.getRangeAt(0)
  if (!range) return
  const childNodes = node.childNodes
  let start = range.startOffset
  let end = range.endOffset
  for (const text of childNodes) {
    if (text === range.startContainer) {
      break
    }
    start += (text.textContent ?? '')?.length
  }
  for (const text of childNodes) {
    if (text === range.endContainer) {
      break
    }
    end += (text.textContent ?? '')?.length
  }
  return [start, end]
}

function focusRange(node: Node, range: number[]) {
  if (!node) return
  const selection = getSelection()
  if (!selection) return
  try {
    const newRange = new Range()
    const len = node.textContent?.length
    newRange.setStart(node.firstChild!, Math.min(len ?? 1, range[0]))
    newRange.setEnd(node.firstChild!, Math.min(len ?? 1, range[1]))
    selection.removeAllRanges()
    selection.addRange(newRange)
    ;(node as HTMLElement).focus()
  } catch (err) {
    console.log(err)
  }
}

export const ContentEditable = memo((props: ContentEditableProps) => {
  const { value, onChange, editable, divRef, ...rest } = props

  const innerRef = useRef<HTMLDivElement>(null)
  const [id, setId] = useState(0)
  const isChange = useRef(false)
  const isComposition = useRef(false)

  const { run: dChange } = useDebounceFn(onChange, { wait: 100 })

  const { run: changeEnd } = useDebounceFn(
    () => {
      isChange.current = false
    },
    { wait: 500 },
  )

  const handleChange = useMemoizedFn(event => {
    isChange.current = true
    const newValue = event.target.textContent
    dChange(newValue)
    changeEnd()
  })

  const handleCompositionStart = useMemoizedFn(() => {
    isComposition.current = true
  })

  const handleCompositionEnd = useMemoizedFn(() => {
    isComposition.current = false
  })

  useDeepCompareEffect(() => {
    setId(prev => prev + 1)
  }, [rest])

  useEffect(() => {
    if (isChange.current || isComposition.current) return
    if (!innerRef.current) return
    if (value === innerRef.current.textContent) return
    const isFocus = document.activeElement === innerRef.current

    let range = null
    if (isFocus) {
      range = getRange(innerRef.current)
    }
    innerRef.current.textContent = value

    if (range) {
      focusRange(innerRef.current, range)
    }
    setId(prev => prev + 1)
  }, [value])

  return useMemo(() => {
    return (
      <div
        {...rest}
        ref={moreRef(divRef, innerRef)}
        onInput={handleChange}
        contentEditable={editable ? 'plaintext-only' : false}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
      />
    )
  }, [id, editable])
})
