export enum TaskType {
  FLOW = 'flow',
  PLUGIN = 'plugin',
  COMMAND = 'command',
  AGENT = 'agent',
}

export enum ExecuteType {
  IMMEDIATELY = 'IMMEDIATELY',
  SCHEDULE = 'SCHEDULE',
}

export enum LoopType {
  NONE = 'NO_REPEAT',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  WORKDAY = 'WEEKDAY',
  CUSTOM = 'CUSTOM',
}

export enum CustomLoopType {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  WEEK = 'WEEK',
}

export interface TaskDetail {
  flow_id: string
  is_preset: boolean
  update_time: number
  conversation_id: string
  preset_enable?: boolean
  task_id?: string
  task_name: string
  execution_method: TaskType
  rel_flow_plugin_id: string
  execution_type: ExecuteType
  task_start_time?: string
  task_type?: LoopType
  custom_task_type?: CustomLoopType
  custom_task_config?: string
  run_param: Record<string, any>
}

export enum TaskStatusType {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
}

export interface TaskListResult {
  id: number
  flow_id: string
  task_id: string
  task_name: string
  remark: string
  is_enable: boolean
  is_preset: boolean
  last_task_status: TaskStatusType
  last_task_time: number
}
