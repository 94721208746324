import { useEffect, useMemo, useRef, useState } from 'react'
import { Input, Select } from '@bty/components'
import { SearchOutlined } from '@ant-design/icons'
import useSize from 'ahooks/es/useSize'
import { Tabs } from 'antd'
import { cn } from '../../utils/cn'
import { AgentCard, type AgentCardProps } from './AgentCard'

export interface AgentListProps {
  list: AgentCardProps[]
  type?: string
  onCardClick?: (id: string) => void
}
const ADAPT_WIDTH = 768
export function AgentList({ list, onCardClick, type }: AgentListProps) {
  const [activeKey, setActiveKey] = useState('全部')

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    setActiveKey('全部')
  }, [type])

  const tabItems = useMemo(() => {
    return [
      ...new Set(
        (list || [])
          ?.map(item => {
            return item.labels
          })
          .flat(),
      ),
    ].map(item => {
      return {
        label: item || '',
        key: item || '',
      }
    })
  }, [list])

  const agentList = useMemo(() => {
    return activeKey === '全部'
      ? list
      : list?.filter(item => item.labels?.includes(activeKey))
  }, [list, activeKey])

  const filteredList = useMemo(() => {
    return agentList?.filter(
      v =>
        v.name.includes(searchValue) ||
        v.workspaceName?.includes(searchValue) ||
        v.developer?.includes(searchValue) ||
        v.description.includes(searchValue),
    )
  }, [agentList, searchValue])

  const ref = useRef(null)
  const size = useSize(ref)

  const cardWidth = useMemo(() => {
    if (!size?.width) return 0
    if (size.width < ADAPT_WIDTH) return '100%'
    return 'calc((100% - 48px) / 3)'
  }, [size?.width])

  const selectItems = useMemo(() => {
    return tabItems.map(item => {
      return {
        label: item.label,
        value: item.key,
      }
    })
  }, [tabItems])

  return (
    <div ref={ref} className='h-full flex flex-col'>
      <div
        className={cn(
          'flex items-start justify-between w-full  md:px-[64px] sm:px-[16px] px-[16px]',
          {
            'mb-[16px]': (size?.width || 0) < ADAPT_WIDTH,
          },
        )}
      >
        {(size?.width || 0) > ADAPT_WIDTH ? (
          <Tabs
            activeKey={activeKey}
            className='flex-1 overflow-hidden'
            size='small'
            tabBarStyle={{ border: 'none' }}
            items={tabItems}
            onTabClick={setActiveKey}
          />
        ) : (
          <Select
            className='overflow-hidden] w-[168px]'
            options={selectItems}
            value={activeKey}
            onChange={a => {
              console.log(a)
              setActiveKey(a)
            }}
          />
        )}

        <Input
          prefix={<SearchOutlined />}
          className='[&.ant-input-outlined]:bg-[#626999]/[0.08] [&.ant-input-outlined]:border-transparent !w-[200px] ml-[26px]'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder='搜索 Agent'
        />
      </div>
      <div className='mt-20px flex-1  overflow-auto'>
        <div className='flex flex-wrap gap-[24px]  md:px-[64px] sm:px-[16px] px-[16px]'>
          {filteredList?.map(item => {
            return (
              <div
                key={item.id}
                style={{ width: cardWidth }}
                className='h-[102px]'
              >
                <AgentCard {...item} onClick={onCardClick} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
