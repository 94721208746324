import { FILE_MIME_TYPE_MAP, type FileMimeType } from '@bty/global-types/file'

export function getFileVariant(type: FileMimeType) {
  switch (type) {
    case FILE_MIME_TYPE_MAP.docx:
      return 'doc'
    case FILE_MIME_TYPE_MAP.pdf:
      return 'pdf'
    case FILE_MIME_TYPE_MAP.gif:
    case FILE_MIME_TYPE_MAP.jpeg:
    case FILE_MIME_TYPE_MAP.jpg:
    case FILE_MIME_TYPE_MAP.png:
    case FILE_MIME_TYPE_MAP.webp:
      return 'image'
    case FILE_MIME_TYPE_MAP.html:
      return 'html'
    case FILE_MIME_TYPE_MAP.svg:
      return 'svg'
    case FILE_MIME_TYPE_MAP.ppt:
    case FILE_MIME_TYPE_MAP.pptx:
      return 'ppt'
    case FILE_MIME_TYPE_MAP.mp3:
    case FILE_MIME_TYPE_MAP.mpeg:
    case FILE_MIME_TYPE_MAP.wav:
    case FILE_MIME_TYPE_MAP.aac:
    case FILE_MIME_TYPE_MAP.ogg:
    case FILE_MIME_TYPE_MAP.webm:
    case FILE_MIME_TYPE_MAP.m4a:
    case FILE_MIME_TYPE_MAP['x-m4a']:
    case FILE_MIME_TYPE_MAP['x-ms-wma']:
    case FILE_MIME_TYPE_MAP.wma:
    case FILE_MIME_TYPE_MAP.amr:
    case FILE_MIME_TYPE_MAP.flac:
      return 'audio'
    case FILE_MIME_TYPE_MAP.mp4:
    case FILE_MIME_TYPE_MAP.avi:
    case FILE_MIME_TYPE_MAP.mov:
    case FILE_MIME_TYPE_MAP['v-webm']:
    case FILE_MIME_TYPE_MAP['v-ogg']:
    case FILE_MIME_TYPE_MAP['x-msvideo']:
    case FILE_MIME_TYPE_MAP['x-mpeg']:
    case FILE_MIME_TYPE_MAP['x-matroska']:
    case FILE_MIME_TYPE_MAP['x-ms-asf']:
      return 'video'
    case FILE_MIME_TYPE_MAP.reference:
      return 'reference'
    default:
      // if (type.startsWith("text/")) {
      return 'txt'
    // }
  }
}

export function getFileVariantFromUrlOrFileName(
  url: string,
  defaultType?: string,
  fileName?: string,
) {
  let valuePathArr: string[] = []

  try {
    valuePathArr = new URL(url)?.pathname?.split('/') ?? []
  } catch (e) {}

  const valueFileName = valuePathArr[valuePathArr.length - 1]
  const _fileName = fileName ?? valueFileName ?? '未知内容.txt'
  const fileSuffix = _fileName?.split('.')?.slice?.(-1)?.[0]
  const mimeType = (FILE_MIME_TYPE_MAP as any)[fileSuffix ?? defaultType]

  return getFileVariant(mimeType)
}
