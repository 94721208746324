import { Button as AntdButton } from 'antd'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { THEME } from '@bty/constant'
import { Size } from '../const'

export const Button = styled(AntdButton)`
  height: ${props => Size[`${props.size}`]};
  padding: 4px 17.3px;
  font-weight: 500;
  box-shadow: none !important;
  &.ant-btn.ant-btn-primary:not(.ant-btn-dangerous) {
    background-color: ${THEME.colors.primary};
  }

  &.ant-btn-primary {
    border: none;
    color: #fff;
    box-shadow: 0px 4px 8px 0px ${rgba(THEME.colors.primary, 0.24)};
    &:not(:disabled):hover {
      background-color: #9980ff;
    }
    &.ant-btn-dangerous {
      background-color: ${THEME.colors.error};
      box-shadow: 0px 4px 8px 0px ${rgba(THEME.colors.error, 0.24)};
    }
    &:disabled {
      color: rgba(247, 247, 255, 0.4);
      background-color: rgba(123, 97, 255, 0.48);
    }
  }
  &.ant-btn-default {
    box-shadow: 0px 2px 4px 0px ${rgba(THEME.colors.font, 0.08)};
    border-color: ${THEME.colors.line};
    &.ant-btn-dangerous {
      color: ${THEME.colors.error};
    }
    &:not(:disabled):not(.ant-btn-disabled):hover {
      border-color: ${THEME.colors.line};
      color: inherit;
      background: #f3f3f7;
    }
  }
  &.ant-btn {
    border-radius: 8px;
    &.ant-btn-lg {
      && {
        font-size: 14px;
        padding: 4px 17.3px;
        height: ${props => Size[`${props.size}`]};
        border-radius: 8px;
      }
    }
    &.ant-btn-sm {
      font-size: 12px;
      padding: 4px 17.3px;
      height: ${props => Size[`${props.size}`]};
    }
    svg {
      display: inline;
    }
  }
`
