import { ConfigProvider, Tabs } from 'antd'
import { useRequest } from 'ahooks'
import { useMemo, useState } from 'react'
import { ANTD_TOKEN } from '@bty/constant'
import { useBusinessContext } from '../../hooks/useBusinessContext'
import { fetchAgentList, fetchTemplateAgentList } from '../../apis/agent'
import { AgentList } from './AgentList'
import type { AgentCardProps } from './AgentCard'

export interface AgentDiscoveryProps {
  onCardClick?: (id: string) => void
}
const TABS = [
  {
    label: 'Agent模板',
    key: 'Agent模板',
  },
  {
    label: '我的Agent',
    key: '我的Agent',
  },
]

export function AgentDiscovery({ onCardClick }: AgentDiscoveryProps) {
  const { auth, env } = useBusinessContext()

  const [activeKey, setActiveKey] = useState(TABS[0].key)

  const { data } = useRequest(() => fetchAgentList({ auth, env }))

  const { data: templateAgentListRes } = useRequest(() =>
    fetchTemplateAgentList({ auth, env }),
  )

  const myAgentList: AgentCardProps[] = useMemo(() => {
    return (data?.data || [])?.map(v => {
      return {
        id: `${v.flow_id}`,
        avatar: v.icon,
        name: v.flow_name,
        description: v.description,
        developer: v.created_by,
        workspaceName: v.workspace_name,
        labels: ['全部', v.workspace_name],
      }
    })
  }, [data])

  const templateAgentList: AgentCardProps[] = useMemo(() => {
    return (templateAgentListRes?.data || [])?.map(v => {
      return {
        id: v.flowId,
        avatar: v.icon,
        name: v.name,
        description: v.description,
        hot: v.popularity,
        labels: ['全部', ...(v.labels || [])],
      }
    })
  }, [templateAgentListRes])

  const list = activeKey === '我的Agent' ? myAgentList : templateAgentList
  return (
    <ConfigProvider
      theme={{
        token: ANTD_TOKEN,
      }}
    >
      <div className='h-full flex flex-col w-full pb-[16px]'>
        <div className='flex flex-col flex-1 overflow-hidden w-full'>
          <div className='md:px-[64px] sm:pr-[16px] sm:pl-[114px] pl-[114px]'>
            <Tabs
              activeKey={activeKey}
              size='small'
              tabBarStyle={{ border: 'none' }}
              items={TABS}
              onTabClick={setActiveKey}
            />
          </div>
          <div className='flex flex-1 overflow-hidden'>
            <div className='flex-1 overflow-hidden'>
              <AgentList
                list={list}
                onCardClick={onCardClick}
                type={activeKey}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}
