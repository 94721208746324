import type { RefObject } from 'react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useMutationObserver } from 'ahooks'
import { uniq } from 'lodash-es'
import type { TipItem } from '../type'
import { colorSerializer, escapeRegExp } from '../util'

function highlightText(node: Node | null, reg: RegExp, tips: TipItem[]) {
  if (!node || !CSS.highlights) return

  const text = node.textContent ?? ''
  const textNodes = node.childNodes
  const rangePoint: [string, [number, number]][] = []

  const matches = [...text.matchAll(reg)]
  matches.forEach(each => {
    if (!node.textContent) return []
    const tip = tips.find(e => e.value === each[0])
    if (!tip?.color) return []
    const start = Number(each.index)
    const end = start + each[0].length
    if (start === end) return []
    rangePoint.push([colorSerializer(tip.color), [start, end]])
  })

  const ranges: [string, Range][] = []

  let now = 0
  let nowNode = 0
  function loopNode(index: number) {
    while (index > now + (textNodes[nowNode].textContent ?? '').length) {
      now += (textNodes[nowNode].textContent ?? '').length
      nowNode += 1
    }
  }

  rangePoint.forEach(([name, [start, end]]) => {
    try {
      const range = document.createRange()
      loopNode(start)
      range.setStart(textNodes[nowNode], start - now)
      loopNode(end)
      range.setEnd(textNodes[nowNode], end - now)
      ranges.push([name, range] as const)
    } catch {}
  })

  ranges.forEach(each => {
    CSS.highlights.get(each[0])?.add(each[1])
  })

  return () => {
    ranges.forEach(each => {
      CSS.highlights.get(each[0])?.delete(each[1])
    })
  }
}

export interface TextHighlightProps {
  editorRef: RefObject<HTMLElement>
  text: string
  tips: TipItem[]
}

export const TextHighlight = memo((props: TextHighlightProps) => {
  const { tips, text, editorRef } = props

  const [highlightId, setHighlightId] = useState(0)
  const styleRef = useRef<HTMLStyleElement>(null)

  const tipsReg = useMemo(
    () => new RegExp(tips.map(e => escapeRegExp(e.value)).join('|'), 'g'),
    [tips.map(e => e.value).join('')],
  )

  const tipColors = useMemo(() => {
    return tips.map(each => {
      if (!each.color) return ''
      return each.color
    })
  }, [tips.map(e => e.color).join('')])

  useEffect(() => {
    if (!CSS.highlights) return

    tipColors.forEach(each => {
      const key = colorSerializer(each)
      if (!each || CSS.highlights.has(key)) return
      const colorHighlight = new Highlight()
      CSS.highlights.set(colorSerializer(each), colorHighlight)
    })

    if (!styleRef.current) return

    styleRef.current.textContent = uniq(tipColors)
      .map(each => {
        return `.text-editor-input::highlight(${colorSerializer(each)}){color:${each}}`
      })
      .join('')
  }, [tipColors])

  useEffect(() => {
    if (!editorRef.current) return
    return highlightText(editorRef.current, tipsReg, tips)
  }, [tipsReg, text, highlightId])

  // scrollbar 内可用该方法解
  useMutationObserver(
    changes => {
      let changed = false
      for (const change of changes) {
        if (changed) break
        for (const node of change.addedNodes) {
          if (node.contains(editorRef.current)) {
            changed = true
            setHighlightId(prev => prev + 1)
            break
          }
        }
      }
    },
    document.body,
    { childList: true, subtree: true },
  )

  return useMemo(() => {
    return <style ref={styleRef} />
  }, [])
})
