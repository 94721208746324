export const THEME = {
  colors: {
    // 背景颜色
    bg: '#F7F7FA', // 一般做大背景色
    bg_1: '#EEEEF6', // 背景上层图层的hover色，eg:右侧面板的hover色
    bg_2: '#F5F5FA', // 白色背景的hover色
    bg_3: '#626999', // 输入框背景色等, 一般需要配合透明度一起使用
    bg_dark: '#3F3F44', // toast, 气泡等等颜色
    // 边框分割线
    line: '#E1E1E5', // 分割线颜色, 一般使用要配合透明度一起使用
    // 图标&文本颜色
    font: '#17171d', // 最常用的文本颜色
    font_1: '#8D8D99', // 次级文本的颜色，次级标题，图标等等
    font_2: '#979797',
    font_red: '#E54A17', // 红色文本
    // 主色调-紫色
    primary: '#7B61FF', // 紫色强调色
    // 强调色
    success: '#00B042', //  成功色
    error: '#FF5219', // 红色，一般是错误按钮
    warning: '#FE9700', // 金黄色，警告使用
    tips: '#38A6FF', // 蓝色，标记使用, 例如：高亮块
    // 辅助色
    green: '#25BC68', // 绿色
    orange: '#ED9B23', // 橘色
    pink: '#E55C91', // 粉色
    brown: '#B87332', // 棕色
    // flow按钮
    btn_white: '#f7f7ff',
  },
}

export const ANTD_TOKEN = {
  wireframe: false,
  colorInfo: THEME.colors.primary,
  colorPrimaryActive: THEME.colors.primary,
  colorPrimaryBorderHover: THEME.colors.primary,
  colorPrimary: THEME.colors.primary,
  colorText: THEME.colors.font,
  colorPrimaryHover: THEME.colors.primary,
  borderRadius: 8,
  colorPrimaryBg: THEME.colors.bg_1,
  fontSize: 14,
  colorError: THEME.colors.error,
  colorWarning: THEME.colors.warning,
}
