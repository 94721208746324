export function tryParseToJsonObject(text: string) {
  try {
    return JSON.parse(text)
  } catch {}

  return text
}

export function tryParseToObject(json: string) {
  if (typeof json === 'object') return json

  try {
    return JSON.parse(json)
  } catch (e) {
    return {}
  }
}
