import { injectGlobal } from '@emotion/css'

injectGlobal`
.text-editor-form {
  height: 100%;
  width: 100%;
  font-size: 12px;
  padding: 6px;
  background-color: rgb(243, 243, 247);
  border-radius: 6px;
  border: 1px solid transparent;
}

.text-editor-form:focus-within {
  border-color: rgb(123, 97, 255);
}

.text-editor-form-error {
  border-color: #ff5219 !important;
}

.text-editor {
  height: 100%;
  width: 100%;
  position: relative;
  color: #17171d;
  z-index: 1;
}

.text-editor-disabled {
  opacity: 0.85;
  .text-editor-content {
    color: #8D8D99;
  }
}

.text-editor-tip {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  width: 280px;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 9000;
}

.text-editor-tip-item {
  line-height: 24px;
  text-wrap: nowrap;
  height: 24px;
  padding: 0 4px;
  font-family: ui-monospace, monospace;
  border-radius: 4px;
}

.text-editor-tip-item:not(.text-editor-tip-item-hover):hover {
  background-color: #fafafa;
}

.text-editor-tip-item-hover {
  background-color: #f0f0f0;
}

.text-editor-scroll-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.text-editor-scroll {
  height: 100%;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
}

.text-editor-scroll::scrollbar,
.text-editor-scroll::-moz-scrollbar,
.text-editor-scroll::-webkit-scrollbar {
  display: none;
}

.text-editor-scrollbar {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
  border-radius: 10px;
  transition: 500ms;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
}

.text-editor-scrollbar-show,
.text-editor:focus-within .text-editor-scrollbar,
.text-editor:hover .text-editor-scrollbar {
  opacity: 1;
}

.text-editor-scrollbar-hidden {
  display: none;
}

.text-editor-scrollbar-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 500ms;
}

.text-editor-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.text-editor-wrapper {
  min-height: 100%;
  width: 100%;
  position: relative;
  font-family: ui-monospace, 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas',
    'Source Code Pro', 'source-code-pro', monospace;
  cursor: text;
}

.text-editor-input {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: inherit !important;
  background-color: transparent;
  outline: none;
  resize: none;
  caret-color: black;
  line-height: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
}

.text-editor-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
  z-index: -1;
  pointer-events: none;

  * {
    line-height: 22px;
  }
}

.text-editor-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

@keyframes text-editor-cursor-blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.text-editor-last-cursor {
  animation: text-editor-cursor-blink 1s linear infinite;
  width: 2px;
  height: 1em;
  border-radius: 4px;
  display: inline-flex;
  background-image: linear-gradient(
    354deg,
    #e21d6f 18%,
    #d248b6 25%,
    #6d3cff 59%,
    #7b61ff 79%,
    #4598ff 99%
  );
}
`
